import styled from "styled-components";
import { motion } from "framer-motion";

export const EventTitle = styled(motion.h1)`
  color: #383b4b;
  font-family: FiraGO;
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
  margin: 0;
  margin-bottom: 35px;
`;

export const OrderP = styled(motion.p)`
  color: #1b1f34;
  font-family: FiraGO;
  font-size: 13px;
  line-height: 18px;
  margin: 0;
  font-weight: normal;
  margin-top: 1rem;
`;

export const StaticDiv = styled("div")`
  margin-top: 2rem;
`;

export const CustomDiv = styled("div")`
  margin-top: 2rem;
`;

export const CheckSection = styled(motion.div)`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: 1rem;
`;

export const CheckItem = styled(motion.div)`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin: 0;
  gap: 0.8rem;
`;
