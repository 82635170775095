import styled from "styled-components";

export const StatusPageWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`;

export const StatusPageIcon = styled("img")`
  width: 120px;
  height: 120px;
  margin-top: 140px;
`;

export const StatusPageText = styled("h5")`
  color: #1b1f34;
  font-family: FiraGO;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
  margin: 35px 0;
`;

export const StatusPageInfo = styled("p")`
  color: rgba(27, 31, 52, 0.7);
  font-family: FiraGO;
  font-size: 14px;
  line-height: 22px;
  margin-top: ${props => (props.floatTop ? "auto" : "unset")};
`;

export const ReloadButton = styled("button")`
  height: 58px;
  width: 100%;
  border: 1px solid #e305b1;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e005b6;
  font-family: "BPG Web 002 Caps";
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  cursor: pointer;
`;
