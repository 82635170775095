import styled from "styled-components";
import { motion } from "framer-motion";

export const GreetBody = styled(motion.div)`
  width: 100%;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  z-index: 2;
  border-radius: 30px 30px 0 0;
  padding: 30px 25px;
  /* margin-top: 180px; */
  display: flex;
  flex-direction: column;
  /* min-height: calc(100% - 180px); */
`;

export const GreetHeading = styled(motion.p)`
  color: #383b4b;
  font-family: FiraGO;
  font-size: 18px;
  font-weight: 500;
  line-height: 29px;
  margin: 0;
  text-align: center;
  margin-bottom: 28px;
  margin-top: 10px;
`;

export const GreetDesc = styled(motion.h1)`
  color: #1b1f34;
  font-family: FiraGO;
  font-size: 15px;
  line-height: 24px;
  margin: 0;
  font-weight: normal;
  text-align: center;
`;

export const Span = styled(motion.h1)`
  color: #1b1f34;
  font-family: FiraGO;
  font-size: 15px;
  line-height: 24px;
  margin: 0;
  font-weight: bold;
  text-align: center;
`;
