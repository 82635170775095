import { motion } from "framer-motion";
import styled from "styled-components";

export const InputContainer = styled(motion.div)`
  width: 100%;
  position: relative;
  margin-bottom: 12px;
`;

export const InputLabel = styled("label")`
  color: #595d58;
  font-family: FiraGO;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: rgba(27, 31, 52, 0.4);
  font-family: FiraGO;
  font-size: ${(props) => (props.active ? "11px" : "14px")};
  line-height: 14px;
  position: absolute;
  top: ${(props) => (props.active ? "8px" : "22px")};
  left: 15px;
  transition: all 0.175s ease-out;
  pointer-events: none;
`;

export const InputElement = styled("input")`
  border: none;
  outline: none;
  border-radius: 9px;
  background-color: #f7f7fa;
  width: 100%;
  padding: 0 1rem;
  height: 58px;
  padding-top: 0.875rem;
  color: #1b1f34;
  font-family: FiraGO;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  padding-right: 3rem;
  text-transform: capitalize;
`;

export const InputInfoContainer = styled("div")`
  position: absolute;
  top: 18px;
  right: 15px;
`;

export const InputInfoIcon = styled("img")`
  width: 18px;
  height: 18px;
  margin-left: 6px;
  padding-left: 2px;
  cursor: pointer;
`;

export const InputInfoDesc = styled("p")`
  position: absolute;
  top: -85px;
  right: 10px;
  border-radius: 9px;
  background-color: #259be5;
  padding: 0.5rem;
  color: #ffffff;
  font-family: FiraGO;
  font-size: 14px;
  line-height: 17px;
  width: 200px;
`;
