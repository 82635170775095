import styled from "styled-components";

export const HeaderWrapper = styled("div")`
  grid-row: 1;
  grid-column: 1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("/images/bbg.png") no-repeat center center fixed;
  background-size: cover;
  height: 100%;
  backdrop-filter: blur(10px);
  overflow: hidden;
  /* position: fixed;
  bottom: 20;
  left: 0; */
  /* background-repeat: no-repeat;
  background-position: center -400px;
  background-size: center; */
  @media (max-width: 1200px) {
    background: url("/images/long.png") no-repeat center center fixed;
    background-size: cover;
    height: 100%;
    overflow: hidden;
    /* background-size: center; */
    /* position: absolute; */
    /* background-position: center -200px;
    background-size: cover;

    width: 100%; */
    /* height: 100px; */
    /* height: 100%; */
    /* background-image: url("/images/long.png"); */
  }
  transition: all 5s;
`;

export const HeaderLogo = styled("div")`
  position: fixed;
  top: 30px;
  left: calc(50% - 63px);
  width: 126px;
  height: 110px;
  background-image: url(${(props) => props.img});
  transition: all 5s;
`;
