import React, { useState } from "react";
import {
  InputContainer,
  InputLabel,
  InputElement,
  InputInfoContainer,
  InputInfoDesc,
  InputInfoIcon,
} from "./style";

const Input = ({ title, onChange, info, variants, value }) => {
  const [labelFloating, setLabelFloating] = useState(value);
  const [inputValue, setInputValue] = useState(value);

  return (
    <InputContainer variants={variants}>
      <InputLabel active={labelFloating}>{title}</InputLabel>
      {info && <InputInfo />}
      <InputElement
        value={inputValue}
        onFocus={() => setLabelFloating(true)}
        onBlur={() => setLabelFloating(!!inputValue)}
        onChange={(e) => {
          onChange(e.currentTarget.value);
          setInputValue(e.currentTarget.value);
        }}
      ></InputElement>
    </InputContainer>
  );
};

const InputInfo = () => {
  const [open, setOpen] = useState(false);
  return (
    <InputInfoContainer>
      <InputInfoIcon
        onClick={() => setOpen(!open)}
        src="/icons/blue_info.svg"
      />
      {open && (
        <InputInfoDesc>მიუთითეთ ბილეთზე არსებული 16 ნიშნა კოდი. </InputInfoDesc>
      )}
    </InputInfoContainer>
  );
};

export default Input;
