import React, { useState } from "react";
import { GreetBody, GreetHeading, GreetDesc, Span } from "./style";
import Button from "../common/button";

const itemVariant = {
  closed: { opacity: 0, y: 15 },
  open: {
    opacity: 1,
    y: 0,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
};

const Greet = ({ onNext }) => {
  return (
    <>
      <GreetHeading variants={itemVariant}>
        მთის კურორტების აბონემენტი
      </GreetHeading>
      <GreetDesc variants={itemVariant}>
        შეძენილი ბარათის გააქტიურებისთვის აუცილებელია <Span>ვერიფიკაცია</Span>
      </GreetDesc>
      <Button
        variants={itemVariant}
        style={{ marginTop: "auto" }}
        onClick={() => onNext()}
      >
        დაწყება
      </Button>
    </>
  );
};

export default Greet;
