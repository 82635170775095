import styled from "styled-components";
import { motion } from "framer-motion";

const Layout = styled(motion.div)`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  @media (min-width: 550px) {
    max-height: 700px;
    max-width: 550px;
    border-radius: 30px 30px 0 0;
    top: unset;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
  }
`;

export default Layout;
