import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  UploadImageContainer,
  UploadImageWrapper,
  UplaodImageButton,
  UploadImageCondition,
  UploadTitle,
  UploadImageWarning,
  UploadImageImg,
  BackButton,
} from "./style";
import Button from "../common/button";
import EXIF from "exif-js";
import Modal from "../Modal";

/*global blazeface*/

// const bodyVariant = {
//   open: {
//     y: 0,
//     borderRadius: "0 0 0 0",
//   },
//   closed: {
//     y: 180,
//     borderRadius: "30px 30px 0 0",
//   },
// };

const contentVariant = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
};

function base64ToArrayBuffer(base64) {
  base64 = base64.replace(/^data\:([^\;]+)\;base64,/gim, "");
  var binaryString = atob(base64);
  var len = binaryString.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

const resizerConfig = {
  quality: 0.6,
  maxWidth: 700,
  maxHeight: 700,
  autoRotate: true,
};

const ImageUpload = ({ onBack, onNext }) => {
  const uploadInputRef = useRef(null);
  const [imageBase64, setImageBase64] = useState("");
  const [rotation, setRotation] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [imageFormData, setImageFormData] = useState(null);
  const [didSend, setDidSend] = useState(false);
  const [clickCounter, setClickCounter] = useState(0);
  const [processing, setProcessing] = useState(false);

  const findFaces = useCallback(async () => {
    if (typeof blazeface != "undefined") {
      const model = await blazeface.load();
      const img = document.querySelector("#photoImage");
      const predictions = await model.estimateFaces(img, false);
      if (predictions.length > 0) {
        setProcessing(false);
        setShowModal(false);
        setImageFormData(imageBase64.replace(/\=/g, ""));
      } else {
        setProcessing(false);
        setShowModal(true);
        setImageBase64("");
      }
    }
  }, [imageBase64]);

  const handleImageSelect = (e) => {
    const input = e.currentTarget;
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      var optimizedReader = new FileReader();
      reader.onload = function (e) {
        setImageBase64(e.target.result);
        var exif = EXIF.readFromBinaryFile(
          base64ToArrayBuffer(e.target.result)
        );
        if (exif && exif.Orientation) {
          setRotation(exif.Orientation);
        }
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  useEffect(() => {
    if (!!imageBase64) {
      setProcessing(true);
      findFaces();
    }
  }, [imageBase64]);

  return (
    <>
      {processing && (
        <div
          style={{
            background: "#fff",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 10000,
          }}
        >
          <img style={{ width: 70, height: 70 }} src="/images/loading.svg" />
        </div>
      )}
      <UploadImageContainer
        transition={{
          type: "spring",
          mass: 1,
          stiffness: 250,
          damping: 33,
          restSpeed: 2,
          restDelta: 0.5,
        }}
        variants={contentVariant}
      >
        <BackButton onClick={() => onBack(true)} src="/icons/back.svg" />
        <input
          ref={uploadInputRef}
          id="selfie-input"
          accept="image/*"
          type="file"
          style={{ display: "none" }}
          onChange={handleImageSelect}
        />
        <canvas
          id="canvas"
          width="500"
          height="500"
          style={{ display: "none" }}
        />
        <img id="photoImage" src={imageBase64} style={{ display: "none" }} />
        <UploadImageWrapper
          onClick={() => {
            setClickCounter(clickCounter + 1);
            setImageBase64(null);
            setImageFormData(null);
            uploadInputRef.current.click();
          }}
        >
          <UploadImageImg
            style={{
              transform: `rotate(${
                imageBase64
                  ? rotation === 3
                    ? 0
                    : rotation == 4
                    ? 0
                    : rotation === 6
                    ? 0
                    : rotation === 8
                    ? 0
                    : 0
                  : 0
              }deg)`,
              backgroundImage: `url(${
                imageBase64 ? imageBase64 : "/images/img-placeholder.svg"
              })`,
            }}
            // src={imageBase64 ? imageBase64 : "/images/img-placeholder.svg"}
          />
          <UplaodImageButton>
            <img src="/icons/plus.svg" />
          </UplaodImageButton>
        </UploadImageWrapper>
        <UploadTitle>
          ვერიფიკაციის დასასრულებლად საჭიროა თქვენი ფოტო
        </UploadTitle>
        <UploadImageCondition>
          აღნიშნული ბარათი ეკუთვნის კონკრეტულ პიროვნებას და მისი გადაცემა სხვა
          პირზე აკრძალულია
        </UploadImageCondition>
        <UploadImageCondition>
          ფოტოზე კარგად უნდა ჩანდეს თქვენი სახე
        </UploadImageCondition>
        <UploadImageWarning>
          <img
            style={{ position: "relative", top: 3, left: -3 }}
            src="/icons/alert.svg"
          />{" "}
          გაგზავნილი ინფორმაციის ცვლილება აღარ იქნება შესაძლებელი
        </UploadImageWarning>
        <Button
          style={{ opacity: didSend ? 0.7 : 1 }}
          onClick={() => {
            if (clickCounter > 0) {
              if (didSend) {
                return;
              }
              if (imageFormData) onNext(imageFormData);
              setDidSend(true);
            }
          }}
        >
          {didSend ? "მუშავდება" : "გაგზავნა"}
        </Button>
      </UploadImageContainer>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        reUpload={() => {
          uploadInputRef.current.click();
          setShowModal(false);
        }}
      />
    </>
  );
};

export default ImageUpload;
