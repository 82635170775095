import styled from "styled-components";
import { motion } from "framer-motion";

export const UploadImageContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  position: relative;
  z-index: 3;
  min-height: 100%;
`;

export const UploadImageWrapper = styled("label")`
  border-radius: 50%;
  position: relative;
  background-color: #f5f5f5;
  width: 177px;
  height: 177px;
  cursor: pointer;
`;

export const UploadImageImg = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const UplaodImageButton = styled("div")`
  height: 57px;
  width: 57px;
  background-color: #ffffff;
  box-shadow: 0 4px 9px 0 rgba(61, 2, 85, 0.09);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  bottom: 0;
  right: 0;
  position: absolute;
`;

export const UploadTitle = styled("h3")`
  font-size: 16px;
  font-family: FiraGO;
  color: #1b1f34;
  text-align: center;
  line-height: 24px;
  margin: 30px 0;
  font-weight: 500;
  text-shadow: 0 0 0 #1b1f3499;
`;

export const UploadImageCondition = styled("p")`
  font-size: 14px;
  font-family: FiraGO;
  color: #1b1f34;
  line-height: 22px;
  padding-left: 1rem;
  position: relative;
  width: 100%;
  margin-bottom: 0.5rem;
  margin-top: 0;
  &::before {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    background-color: #1b1f34;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 8px;
  }
`;

export const UploadImageWarning = styled("h5")`
  color: #8d8f9a;
  font-family: FiraGO;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  font-weight: normal;
  margin-top: auto;
`;

export const BackButton = styled("img")`
  display: block;
  width: 25px;
  height: 25px;
  margin-right: auto;
  margin-bottom: 40px;
  cursor: pointer;
`;
