import React, { useState } from "react";
import { EventTitleHeading, EventTitle } from "./style";
import Input from "../common/input";
import Button from "../common/button";
import axios from "axios";

// const bodyVariant = {
//   open: {
//     y: 0,
//     transition: {
//       staggerChildren: 0.1,
//       delayChildren: 0.4,
//       type: "spring",
//       mass: 1,
//       stiffness: 200,
//       damping: 33,
//     },
//   },
//   closed: { y: 180 },
// };

const itemVariant = {
  closed: { opacity: 0, y: 15 },
  open: {
    opacity: 1,
    y: 0,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
};

const Submission = ({ onNext, tktId }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [tktNumber, setTktNumber] = useState(tktId ? tktId : "");
  const [cardNumberIsValid, setCardNumberisValid] = useState(true);
  const checkForNext = () => {
    console.log(firstName, lastName, tktNumber);
    if (firstName.length > 0 && lastName.length > 0 && tktNumber.length > 0) {
      axios
        .get(
          `https://api.tkt.ge/v2/tickets/verify?ticketIdentifier=${tktNumber}&api_key=dad88b07-cd98-4a26-9171-59de30f3318b`
        )
        .then((res) => {
          const name = `${firstName} ${lastName}`;
          if (res.data.Success) {
            setCardNumberisValid(true);
            onNext({ name, tktNumber });

            console.log({ name, tktNumber });
          } else {
            setCardNumberisValid(false);
            console.log({ name, tktNumber });
          }
        });
    }
  };
  return (
    <>
      <EventTitle variants={itemVariant}>მთის კურორტების აბონემენტი</EventTitle>
      <Input variants={itemVariant} title="სახელი" onChange={setFirstName} />
      <Input variants={itemVariant} title="გვარი" onChange={setLastName} />
      {/* <Input variants={itemVariant} title="პირადი ნომერი" onChange={setPId} /> */}
      <Input
        variants={itemVariant}
        title="ბილეთის  ნომერი"
        info
        onChange={setTktNumber}
        value={tktNumber}
      />
      {!cardNumberIsValid && (
        <p
          style={{
            margin: "-1px 0 1rem 6px",
            color: "#1fa3e8",
            fontFamily: "FiraGO",
          }}
        >
          არასწორი ბილეთის ნომერი!
        </p>
      )}
      <Button
        variants={itemVariant}
        style={{ marginTop: "auto" }}
        hasArrow
        onClick={() => checkForNext()}
      >
        შემდეგი
      </Button>
    </>
  );
};

export default Submission;
