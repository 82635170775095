import styled from 'styled-components';
import { motion } from 'framer-motion';

export const FormTitle = styled('h1')`
  color: #2d332c;
  font-family: FiraGO;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
`;

export const FormImgContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UploadImgWrapper = styled('div')`
  display: block;
  flex-basis: 40%;
  margin-right: 1rem;
  flex-shrink: 0;
`;

export const UploadImgWrapperAR = styled('div')`
  width: 1005;
  padding-top: 80%;
  height: 0;
  position: relative;
`;

export const UploadImg = styled('img')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 7px;
  height: 100%;
`;

export const UploadImgDesc = styled('div')`
  flex-basis: 60%;
  flex-grow: 0;
`;

export const UploadImgDescItem = styled('p')`
  color: #9e9e9e;
  font-family: FiraGO;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
`;

export const ChangeImg = styled('span')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #9e9e9e;
  font-family: FiraGO;
  font-size: 14px;
  line-height: 17px;
  margin-top: 12px;
  margin-bottom: 1.5rem;
`;

export const WarningInfo = styled('p')`
  color: #9e9e9e;
  font-family: FiraGO;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  margin-top: 20px;
`;

export const FormBody = styled('div')`
  width: 100%;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  z-index: 2;
  border-radius: 30px 30px 0 0;
  padding: 30px 25px;
  /* margin-top: 180px; */
  display: flex;
  flex-direction: column;
  /* min-height: calc(100% - 180px); */
`;

export const EventTitleHeading = styled(motion.p)`
  color: #383b4b;
  font-family: FiraGO;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
`;

export const EventTitle = styled(motion.h1)`
  color: #383b4b;
  font-family: FiraGO;
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
  margin: 0;
  margin-bottom: 35px;
`;
