import React, { useState } from "react";
import "./App.css";
import Layout from "./components/layout";
import Header from "./components/header";
import Submission from "./components/submission";
import WhitePaper from "./components/whitePaper";
import ImageUpload from "./components/imageUpload";
import Greet from "./components/greet";
import StatusPage from "./components/statusPage";
import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";
import Submission2 from "./components/submition2";

// const variants = { closed: {}, open: {} };

const page0 = {
  height: "300px",
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
};

const page1 = {
  height: "calc(100% - 250px)",
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
};

const page2 = {
  height: "calc(100% - 130px)",
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
};

const page3 = {
  height: "100%",
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
};

const bodyVariant = {
  open: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.4,
      type: "spring",
      mass: 1,
      stiffness: 200,
      damping: 33,
    },
  },
  closed: {},
};

function App() {
  const [page, setPage] = useState(0);
  const [didError, setDidError] = useState(false);
  const [firstPageInfo, setFirstPageInfo] = useState({
    tktNumber: "",
    name: "",
    Location: "",
  });
  const [errMsg, setErrMsg] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const queryStr = urlParams.get("ticketId");

  return (
    <>
      <Header />
      <Layout>
        <WhitePaper
          initial={false}
          transition={{
            type: "spring",
            stiffness: 400,
            damping: 40,
          }}
          animate={
            page === 0 ? page0 : page === 1 ? page1 : page === 2 ? page2 : page3
          }
        >
          <AnimatePresence exitBeforeEnter={true}>
            <motion.div
              initial="closed"
              animate="open"
              exit="closed"
              key={page}
              variants={bodyVariant}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
            >
              {page === 0 && (
                <Greet
                  onNext={(form) => {
                    setFirstPageInfo(form);
                    setPage(1);
                  }}
                />
              )}
              {page === 1 && (
                <Submission
                  tktId={queryStr}
                  onNext={(form) => {
                    setFirstPageInfo(form);
                    setPage(2);
                  }}
                />
              )}
              {page === 2 && (
                <Submission2
                  onNext={(form) => {
                    setFirstPageInfo((prev) => {
                      return { ...prev, Location: form };
                    });
                    setPage(3);
                  }}
                />
              )}
              {page === 3 && (
                <ImageUpload
                  onBack={(status) => {
                    if (status) {
                      setPage(page - 1);
                    }
                  }}
                  onNext={(base64) => {
                    axios
                      .post(
                        "https://api.tkt.ge/v2/tickets/verify?api_key=dad88b07-cd98-4a26-9171-59de30f3318b",
                        {
                          TicketIdentifier: firstPageInfo.tktNumber,
                          Name: firstPageInfo.name,
                          Photo: `${base64}=`,
                          Address: firstPageInfo.Location,
                        }
                      )
                      .then((res) => {
                        setPage(4);
                        if (res.data.Success) {
                          setDidError(false);
                        } else {
                          setErrMsg(res.data.Errors[0].Description);
                          setDidError(true);
                        }
                      })
                      .catch(() => {
                        setDidError(true);
                      });
                  }}
                />
              )}
              {page === 4 && <StatusPage errMsg={errMsg} didError={didError} />}
            </motion.div>
          </AnimatePresence>
        </WhitePaper>
      </Layout>
    </>
  );
}

export default App;
