import styled from "styled-components";
import { motion } from "framer-motion";

export const WhitePaperWrapper = styled(motion.div)`
  width: 100%;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  z-index: 2;
  /* border-radius: 30px 30px 0 0; */
  padding: 30px 25px;
  /* margin-top: 180px; */
  display: flex;
  flex-direction: column;
  /* min-height: calc(100% - 180px); */
  overflow-y: auto;
`;
