import React from "react";
import {
  StatusPageWrapper,
  StatusPageIcon,
  StatusPageText,
  StatusPageInfo,
  ReloadButton,
} from "./style";

const StatusPage = ({ didError, errMsg }) => {
  if (errMsg.includes("already")) {
    errMsg = "ბილეთი უკვე ვერიფიცირებულია!";
  } else if (errMsg.includes("upload")) {
    errMsg = "ფოტოს ატვირთვისას დაფიქსირდა შეცდომა!";
  } else if (errMsg.includes("found")) {
    errMsg = "ბილეთი არ მოიძებნა!";
  }

  return (
    <StatusPageWrapper>
      <StatusPageIcon
        src={didError ? "/icons/error.svg" : "/icons/success.svg"}
      />
      {didError && (
        <StatusPageText style={{ color: "rgba(27,31,52,0.7)" }}>
          {errMsg}
        </StatusPageText>
      )}
      <StatusPageText>
        {didError
          ? "კითხვების შემთხვევაში დაგვიკავშირდით"
          : "ვერიფიკაცია წარმატებით დასრულდა"}
      </StatusPageText>

      {!didError && <StatusPageText>თქვენი ბარათი მზადდება</StatusPageText>}
      {didError && (
        <ReloadButton onClick={() => window.location.reload(false)}>
          სცადეთ ხელახლა
        </ReloadButton>
      )}
      <StatusPageInfo floatTop={didError}>
        კითხვების შემთხვევაში დაგვიკავშირდით: 032 3 19 55 77
      </StatusPageInfo>
    </StatusPageWrapper>
  );
};

export default StatusPage;
