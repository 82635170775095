import React from "react";
import { ButtonWrapper } from "./style";

const Button = props => {
  return (
    <ButtonWrapper {...props}>
      {props.children}
      {props.hasArrow && <img src="/icons/arrow.svg" />}
    </ButtonWrapper>
  );
};
export default Button;
