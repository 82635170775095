import { motion } from "framer-motion";
import styled from "styled-components";

export const ButtonWrapper = styled(motion.button)`
  border-radius: 9px;
  background: linear-gradient(212.9deg, #3a7bd5 0%, #00d2ff 100%);
  box-shadow: 0 20px 24px -10px #18aeee;
  padding: 20px 33px;
  color: #ffffff;
  font-family: "BPG Web 002 Caps";
  font-size: 15px;
  display: flex;
  justify-content: ${(props) => (props.hasArrow ? "space-between" : "center")};
  align-items: center;
  width: 100%;
  border: none;
  cursor: pointer;
`;
