import styled from "styled-components";
export const ModalContent = styled("div")`
  width: 327px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: 0 5px 14px 0 rgba(19, 28, 52, 0.14);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50px;
  z-index: 100;
  margin: 0 auto;
  left: 0;
  right: 0;
  transform: translateY(50%);
`;

export const CloseModalIcon = styled("img")`
  height: 12px;
  width: 11.99px;
  align-self: flex-end;
  cursor: pointer;
`;

export const ModalIcon = styled("img")`
  height: 46px;
  width: 46px;
  margin-bottom: 17px;
`;

export const ModalText = styled("p")`
  color: #1b1f34;
  font-family: FiraGO;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 24px;
`;

export const ModalButton = styled("button")`
  height: 48px;
  width: 286px;
  border: 1px solid rgba(231, 4, 170, 0.93);
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e904a7;
  font-family: "BPG Web 002 Caps";
  font-size: 13px;
  line-height: 15px;
  background-color: #fff;
  cursor: pointer;
`;

export const ModalBackdrop = styled("div")`
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
`;
