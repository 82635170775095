import React, { useState } from "react";
import {
  ModalContent,
  CloseModalIcon,
  ModalIcon,
  ModalText,
  ModalButton,
  ModalBackdrop
} from "./style";

const Modal = ({ show, onClose, reUpload }) => {
  if (!show) {
    return <></>;
  }
  return (
    <>
      <ModalBackdrop></ModalBackdrop>
      <ModalContent>
        <CloseModalIcon src="/icons/close.svg" onClick={onClose} />
        <ModalIcon src="/images/noun_X_1890844.svg" />
        <ModalText>
          სურათზე სახე ვერ მოიძებნა. ფოტოზე კარგად უნდა ჩანდეს თქვენი სახე
        </ModalText>
        <ModalButton onClick={reUpload}>ახლიდან გადაღება</ModalButton>
      </ModalContent>
    </>
  );
};

export default Modal;
